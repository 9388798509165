@mixin center-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

@import './variables.scss';

@mixin centered-main {
  max-width: $maxWidth;
  width: 100%;
}

@mixin centered-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin main-title($font-size, $line-height, $text-align: left) {
  font-size: $font-size;
  font-weight: 700;
  line-height: $line-height;
  color: #6161ff;
  font-style: normal;
  text-align: $text-align;
  margin: 0;
}
