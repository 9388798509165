@import '../../mixins.scss';

.headerWrapper {
  width: 100%;
  @include centered-column;
  position: relative;
}

.header {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 72px;
  padding: 0 48px;
  margin: 0 auto;
}

.avatarWrapper {
  position: relative;
  margin-left: 32px;
  z-index: 3;

  &:hover {
    cursor: pointer;
  }
}

.container {
  position: absolute;
  bottom: -72px;
  right: 0px;
  width: max-content;
  padding: 0px;

  box-shadow: 0px 6px 60px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.signOut {
  padding: 16px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.shape {
  position: absolute;
  right: 270px;
}

.notificationContainer {
  position: absolute;
  z-index: 20;
  top: 70px;
  right: 22px;
  width: max-content;
  padding: 0;
  margin: 0;
}

.iconWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 8px 6px 6px;
  border-radius: 3px;

  &:hover {
    cursor: pointer;
    background-color: #f0f3ff;
  }

  .pointWrapper {
    position: absolute;
    right: 2px;
    top: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
  }
}
