@import "../../styles/themes";
@import "../../styles/typography";
@import "../../styles/global-css-settings";

.attentionBox {
  position: relative;
  padding: var(--spacing-medium);
  border-radius: var(--border-radius-medium);
  width: fit-content;
  height: fit-content;
  @include theme-prop(color, dark-color);
}

.title {
  margin: 0;
  font-weight: 700 !important;
  padding: 0 var(--spacing-xs);
}

.textCompact {
  display: flex;
  align-items: center;
  padding-right: var(--spacing-medium);
}

.text,
.titleContainer {
  @include theme-prop(color, primary-text-color);
}

.typePrimary {
  @include theme-prop(color, primary-text-color);
  @include theme-prop(background-color, primary-selected-color);
}

.typeDanger {
  @include theme-prop(background-color, negative-color-selected);
}

.typeSuccess {
  @include theme-prop(background-color, positive-color-selected);
}

.typeDark {
  @include theme-prop(background-color, primary-background-hover-color);
  @include theme-prop(color, primary-text-color);
}

.typeWarning {
  @include theme-prop(background-color, warning-color-selected);
  @include theme-prop(color, primary-text-color);
}

.titleContainer {
  margin-bottom: var(--spacing-small);
}

.titleContainer:empty {
  margin-bottom: 0;
}

.titleContainerIcon {
  margin-right: var(--spacing-xs);
}

.closeIcon.closeIcon {
  color: var(--primary-text-color);
}

.closeIconWrapper {
  position: absolute;
  top: $spacing-small-medium;
  right: $spacing-small-medium;
}

.closeIconCompact {
  top: calc(50% - 16px);
}


.paragraph {
  margin: 0;
}

.paragraph {
  margin: 0;
}
