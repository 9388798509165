@import '../../variables.scss';
@import '../../mixins.scss';

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  gap: 96px;

  height: 100vh;
  width: 100%;
  padding: 24px 40px;
}

.center {
  @include center-flex;
  flex-direction: column;
  text-align: center;
}

.title {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  margin: 0;
  margin-top: 20px;
}

.text {
  font-size: 11px;
  line-height: 16px;
  width: 240px;
}
