.showMore {
  color: #6161ff;
  font-size: 18px;
  line-height: normal;
  letter-spacing: -0.165px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    border-bottom: 1px solid transparent;
    gap: 8px;

    &:hover {
      border-bottom-color: #6161ff;
      cursor: pointer;
    }
  }

  .icon {
    transform: rotate(90deg);
    margin-top: 4px;
  }
}
