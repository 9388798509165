@import "../../styles/themes";
@import "../../styles/typography";
@import "../../styles/global-css-settings";

.labelComponentWrapper {
  @include vibe-text(small, normal);
  display: flex;
  padding-bottom: var(--spacing-xs);
  align-items: center;
}

.labelComponentIcon {
  margin: 0 var(--spacing-small) 0 var(--spacing-xs);
  line-height: 24px;
  color: var(--secondary-text-color);
}

.labelComponentText {
  @include theme-prop(color, primary-text-color);
  padding-block: 2px;
}
