.label {
  font-size: 16px;

  font-weight: 500;
  line-height: 24px;
  padding: 2px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  height: max-content;

  color: #ffffff;
  background-color: #000000;
}

.line {
  border: 1px solid #000000;
  color: #000000;
  background-color: #ffffff;
}

.primary {
  border: 1px solid #6161ff;
  color: #ffffff;
  background-color: #6161ff;
}
