@import '../../variables.scss';
@import '../../mixins.scss';

.updates {
  position: relative;
  @include centered-main;
  padding: 96px 10px 148px;
}

.title {
  @include main-title(48px, 57.6px);
}

.wrapper {
  width: 100%;
  background: #000000;
  @include centered-column;
  position: relative;
}

.shapesWrapper {
  @include centered-column;
  width: 100%;
  overflow: hidden;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-top: 30px;
  position: relative;
}

.item {
  display: block;
  position: relative;
  border: 1px solid #ffffff;
  color: #ffffff;
  padding: 20px 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
}

.date {
  color: #ffffff;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}

.shape1 {
  position: absolute;
  top: -136px;
  right: 0;
}

.shape2 {
  position: absolute;
  bottom: -110px;
  left: 0;
}

.shape3 {
  position: absolute;
  bottom: -74px;
  left: 462px;
}
.shape4 {
  position: absolute;

  top: 14px;
  right: -556px;
}

.shape5 {
  position: absolute;

  bottom: 80px;
  left: 10px;
}
