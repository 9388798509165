.wrapper {
  width: 526px;
  border-radius: 12px;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
  height: calc(100vh - 70px);
  background-color: #ffffff;
}

.content {
  width: 100%;
}

.title {
  color: #323338;
  font-size: 24px;
  font-weight: 700;
  line-height: 16px;
  margin: 0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 20px 16px;
  border-bottom: 1px solid #dcdfec;
  background-color: transparent;
}

.open {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 64px;
    background-color: #6161ff;
    left: 50%;
    transform: translateX(-50%);
    bottom: -20px;
  }
}

.settings {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px 34px;

  .name {
    font-size: 21.446px;
    font-weight: 600;
    line-height: 31.605px;
  }

  .desc {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  }
}
