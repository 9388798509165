.wrapper {
  position: relative;
}

.title {
  font-size: 16px;
  line-height: 24px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
  transition: transform 0.5s ease;
}

.filter {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 34px;
  border: 1.892px solid #000000;
  padding: 8px 24px;
  gap: 10px;
  width: max-content;

  &:hover {
    cursor: pointer;
    border-color: #6161ff;
    color: #6161ff;
  }
}

.filter.open {
  .icon {
    transform: rotate(180deg);
  }
}

.filter.active {
  border-color: #6161ff;
  background: #f0f3ff;
  color: #6161ff;
}

.dialog {
  margin-top: 10px;
  padding: 24px;
  box-shadow: 0px 6px 60px 0px rgba(0, 0, 0, 0.1);
}

.label {
  font-size: 16px;
  line-height: 24px;
  margin-left: 10px;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
