@import '../../variables.scss';

.app {
  min-height: 100vh;
}

.mobile {
  display: none;
  position: relative;
}

.content {
  width: 100%;
  margin-left: $sidebarWidth;
}
.desktop {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
}

@media (max-width: 820px) {
  .mobile {
    display: flex;
  }

  .desktop {
    display: none;
  }
}

.center {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.modalBg {
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 99;
}

.error {
  color: red;
}
