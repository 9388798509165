@import '../../variables.scss';

.sidebar {
  height: 100%;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;

  display: flex;
  flex-direction: column;
  width: $sidebarWidth;

  box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.1);

  transition: 0.5s ease;
}

.logos {
  display: flex;
  flex-direction: column;
  padding: 24px 0 0 18px;
  gap: 28px;
  width: max-content;
  position: relative;
}

.mondayLogo {
  display: flex;
  gap: 6px;
}

.mondayLogoText {
  opacity: 0;
  visibility: hidden;
}

.chevron {
  position: absolute;
  width: 36px;
  height: 36px;
  right: 10px;
  top: 20px;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
}

.favoritesText {
  opacity: 0;
  visibility: hidden;
  font-size: 19px;
  line-height: 28px;
}

.content {
  width: 100%;
  opacity: 0;
  visibility: hidden;
}

.sidebar.open {
  width: 548px;
  align-items: flex-start;

  .mondayLogoText,
  .favoritesText,
  .chevron,
  .content {
    visibility: visible;
    opacity: 1;
    transition: 0.3s;
    transition-delay: 0.2s;
  }

  .logos {
    width: 100%;
  }
}

.favoritesWrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  height: 28px;
}

.favIcon {
  width: 23px;
  height: 18px;

  path {
    stroke-width: 2px;
  }
}
