@import '../../variables.scss';
@import '../../mixins.scss';

.block {
  height: 100vh;
  width: 100%;

  padding: 24px 20px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.center {
  @include center-flex;
  flex-direction: column;
  text-align: center;
  margin-top: 10%;
}

.title {
  font-size: 22px;
  font-weight: 600;
  line-height: 34px;
  margin: 0;
  margin-top: 36px;
}

.text {
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  width: 240px;
}

.loginTitle {
  font-size: 22px;
  font-weight: 600;
  line-height: 26.4px;
  margin: 0;
  margin-top: 44px;
}

.loginText {
  font-size: 16px;
  line-height: 24px;
  margin: 6px 0 30px;
}
