@import "~monday-ui-style/dist/mixins";
@import "../../styles/themes";
@import "../../styles/typography";
@import "../../styles/global-css-settings";
@import "../../styles/states";

.wrapper {
  @include reset-button();
  border-radius: $border-radius-small;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform var(--motion-productive-short) var(--motion-timing-transition);
  @include theme-prop(color, primary-text-color);
  @include focus-style();
}

.wrapper:focus,
.wrapper:hover {
  @include theme-prop(background-color, primary-background-hover-color);
}

.open {
  transform: scale(0.95);
  @include theme-prop(background-color, primary-selected-color);
  @include theme-prop(color, primary-color);
}

.open:focus {
  @include theme-prop(background-color, primary-selected-color);
}

.open:hover {
  @include theme-prop(background-color, primary-selected-color);
}

.size16 {
  width: 16px;
  height: 16px;
  @include vibe-text(small, normal);
  @include smoothing-text;
}

.size24 {
  width: 24px;
  height: 24px;
  @include vibe-text(medium, normal);
  @include smoothing-text;
}

.size32 {
  width: 32px;
  height: 32px;
  @include vibe-text(medium, normal);
  @include smoothing-text;
}

.size40 {
  width: 40px;
  height: 40px;
  @include vibe-title(h3, normal);
}

.size48 {
  width: 48px;
  height: 48px;
  @include vibe-title(h2, normal);
}

.referenceIcon {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabled {
  @include theme-prop(border-color, disabled-background-color);
  @include theme-prop(color, disabled-text-color);
  cursor: not-allowed;
}

.disabled:hover {
  background-color: transparent;
}

.text {
  width: 100%;
}

.innerText {
  margin: 0 $spacing-small;
}
