.wrapper {
  width: 100%;
  display: grid;
  gap: 10px;
  grid-auto-columns: 180px;
}

.column {
  grid-auto-flow: column;
}

.checkbox {
  input:checked + div {
    background-color: #6161ff !important;
  }
}

.checkedLabel {
  color: #6161ff !important;
}

.label {
  font-size: 16px !important;
}
