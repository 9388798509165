@import "../../../styles/global-css-settings";
@import "../../../styles/typography";
@import "../../../styles/themes";

.bannerLink {
  text-decoration: underline;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.marginLeft {
  margin-left: $spacing-small;
}
