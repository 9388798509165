.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 780px;
  background-color: #ffffff;
  border-radius: 8px;
  z-index: 9999;
}

.topBox {
  padding: 44px;
  display: flex;
  gap: 30px;
  position: relative;

  .close {
    position: absolute;
    top: 30px;
    right: 26px;
  }

  .image {
    min-width: 140px;
    height: 140px;
  }

  .labels {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
  }

  .title {
    font-size: 26px;
    font-weight: 600;
    line-height: 36.4px;
    margin: 0;
  }

  .description {
    font-size: 16px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-top: 10px;
  }

  .icons {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;

    padding-right: 12px;
  }
}

.line {
  height: 1px;
  background-color: #c3c6d4;
}

.bottomBox {
  padding: 44px 44px 52px 44px;
  display: flex;
  gap: 44px;

  .resources {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 28px;

    .links {
      display: flex;
      flex-direction: column;
      gap: 6px;
      color: #6161ff;
    }

    .title {
      display: inline-block;
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      margin-bottom: 8px;
    }

    .description {
      color: #676879;
      font-size: 16px;
      line-height: 20.627px;
    }

    .text {
      color: #323338;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .updates {
    flex: 1;
    display: flex;
    flex-direction: column;

    .point {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #676879;
    }

    .avatar {
      width: 30px;
      height: 30px;
    }

    .inline {
      display: flex;
      align-items: center;
      gap: 10px;

      margin-top: 12px;
    }

    .owner {
      color: #676879;
      font-size: 14px;
      line-height: 21px;

      white-space: nowrap;
      overflow: hidden;
      width: 300px;

      display: flex;
      align-items: center;
      gap: 4px;

      .labelContainer {
        display: flex;
        align-items: center;
        gap: 6px;
      }
    }

    .notifications {
      display: flex;
      flex-direction: column;
      gap: 18px;
      margin-bottom: 22px;

      .noNotice {
        color: #676879;
        font-size: 16px;
        line-height: 24px;
      }

      .text {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      .title {
        font-weight: 600;
      }

      .metadata {
        font-size: 14px;
        margin-top: 6px;
        line-height: 18px;
        display: flex;
        align-items: center;
        gap: 4px;
        color: #676879;
      }
    }
  }
}
