@import "./theme-mixin";
@import "~monday-ui-style/dist/mixins";

$h1-legacy-line-height: 52px;
$h1-medium-font-size: 22px;
$h1-empty-state-height: 32px;
$h2-empty-state-height: 24px;
$h3-empty-state-height: 24px;
$h4-empty-state-height: 21px;
$h5-empty-state-height: 16px;
$p-empty-state-height: 16px;
$h6-empty-state-height: 12px;
$small-empty-state-height: 12px;


@mixin create-text-classes() {
  @include create-typography-classes($textClasses, text);
}

@mixin create-title-classes() {
  @include create-typography-classes($titleClasses, null);
}

@mixin create-typography-classes($classes, $token-prefix) {
  @each $class-prefix, $weight-types in $classes {
    @each $weight-type in $weight-types {

      .#{$class-prefix}.#{$weight-type} {

        // if class is a heading class
        @if str-slice($class-prefix, 1, 1) == "h" {
          @include vibe-title($class-prefix, $weight-type);
        }
        @else {
          @include vibe-text($class-prefix, $weight-type);
        }
      }
    }
  }
}

$titleClasses: (
    "h1": ("bold", "normal", "light"),
    "h2": ("bold", "normal", "light"),
    "h3": ("bold", "normal", "light")
);

$textClasses: (
    "medium": ("bold", "normal"),
    "small": ("bold", "normal")
);

@mixin base-font($font-variable) {
  @include theme-prop(color, primary-text-color);
  -webkit-font-smoothing: var(--font-smoothing-webkit);
  -moz-osx-font-smoothing: var(--font-smoothing-moz);
  font: $font-variable;
}

@mixin smoothing-text {
  -webkit-font-smoothing: var(--font-smoothing-webkit);
  -moz-osx-font-smoothing: var(--font-smoothing-moz);
}

@mixin label-text {
  @include smoothing-text;
  @include vibe-text(small, normal);
}

// Caption / Subtext (small)
@mixin font-caption {
  @include vibe-text(small, normal);
  @include smoothing-text;
}

@mixin font-input {
  @include vibe-text(small, normal);
}

@mixin line-clamp($lines: 1le) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  white-space: initial; // doesn't work with white-space: no-wrap
}

@mixin heading-padding {
  padding: 0 4px;
}

@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
