@import "../../styles/themes";
@import "../../styles/typography";
@import "../../styles/global-css-settings";

.radioButton {
  display: grid;
  grid-template-columns: 1.5em auto;
  grid-gap: 0.5em;
  cursor: pointer;
}

.inputContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input {
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
}

.control {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1em;
  height: 1em;
  border: 0.1em solid;
  box-sizing: border-box;
  @include theme-prop(border-color, ui-border-color);
  border-radius: 50%;
  transition: border-width var(--motion-productive-medium) var(--motion-timing-enter);
}

input:checked + .control {
  @include theme-prop(border-color, primary-color);
  border-width: 0.3em;
}

input:checked + .labelAnimation {
  animation-name: radioCheckedAnimation;
  animation-duration: var(--motion-productive-medium);
  animation-timing-function: var(--motion-timing-enter);
  animation-fill-mode: forwards;
}

input:disabled + .control {
  border: 0.5em solid;
  @include theme-prop(border-color, disabled-background-color);
  border-radius: 50%;
}

input:checked:disabled + .control {
  border: 0.3em solid;
  @include theme-prop(border-color, disabled-background-color);
  @include theme-prop(background-color, secondary-text-color);
}

.radioButton.disabled {
  cursor: not-allowed;
}

.radioButton.disabled .label {
  color: var(--disabled-text-color);
}

.inputContainer input:focus-visible + .control {
  outline: none;
  @include theme-prop(border-color, primary-color);
  box-shadow: 0 0 0 3px hsla(209, 100%, 50%, 0.5);
  border-radius: 50%;
}

.radioButton:hover
.inputContainer
input:enabled:checked
+ .control {
  @include theme-prop(border-color, primary-hover-color);
}

.radioButton:hover
.inputContainer
input:enabled:not(:checked)
+ .control,
.radioButton:focus-within
.inputContainer
input:enabled:not(:checked)
+ .control {
  @include theme-prop(border-color, primary-text-color);
}

@keyframes radioCheckedAnimation {
  0% {
    height: 1em;
    width: 1em;
  }
  50% {
    height: 0.8em;
    width: 0.8em;
  }
  100% {
    height: 1em;
    width: 1em;
  }
}
