.noNotifications {
  padding-top: 168px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  .text {
    font-size: 21.446px;
    font-weight: 600;
    line-height: 31.605px;
    margin: 12px 0;
  }

  .description {
    font-size: 16px;
    max-width: 264px;
    width: 100%;
    line-height: 24px;
  }
}

.list {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  padding: 8px;
  height: calc(100vh - 70px - 64px); // 70px - top padding, 64px - header height
  overflow-y: auto;
  overflow-x: hidden;

  .unread {
    background: #e4e9ff;
  }

  .item {
    display: flex;
    padding: 10px 24px 10px 10px;
    width: 100%;
    border-radius: 8px;

    &:hover {
      background: #c6cfff;
      cursor: pointer;
    }

    .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
    }

    .image {
      min-width: 94px;
      height: 94px;
      margin-right: 14px;
    }

    .column {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 4px;
    }

    .text {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .metadata {
      color: #676879;
      font-size: 14px;
      line-height: 21px;

      width: 360px;
      overflow: hidden;
      white-space: nowrap;
      display: flex;
      align-items: center;
      gap: 6px;

      .labelContainer {
        display: flex;
        align-items: center;
        gap: 6px;
      }

      .point {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #676879;
      }
    }
  }
}
