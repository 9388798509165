.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  fill: none;
  stroke: #323338;

  &:hover {
    cursor: pointer;
    stroke: #6161ff;
  }
}

.iconActive {
  stroke: #6161ff;
  fill: #6161ff;
}

.favIcon {
  width: 22px;
  height: 18px;
  .path {
    stroke-width: 1.5px;
  }
}
