@import '../../variables.scss';
@import '../../mixins.scss';

.list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 44px;
  min-height: 300px;
}

.item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 0;
  border-bottom: 1px solid #c3c6d4;

  &:last-child {
    border-bottom: none;
  }

  &:not(:first-child):not(:last-child) {
    padding-top: 16px;
  }
}

.image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-right: 20px;
  align-self: flex-start;
  &:hover {
    cursor: pointer;
  }
}

.title {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 33.6px;
  margin: 0;

  padding: 0;
  white-space: nowrap;
}

.description {
  font-size: 16px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  &:hover {
    cursor: pointer;
  }
}

.block {
  width: 472px;
}

.date {
  display: flex;
  align-items: center;
  color: #676879;

  gap: 8px;
  width: 134px;

  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.column {
  display: flex;
  flex-direction: column;
}

.inlineWrapper {
  display: flex;
  align-items: center;
  gap: 26px;
}

.content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.labels {
  display: flex;
  align-items: center;
  gap: 10px;
}

.action {
  &:hover {
    cursor: pointer;
  }
}
