.favorites {
  width: 100%;
  height: 100%;
  padding: 0 18px;
}

.noFavorites {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 130px;
  text-align: center;

  .title {
    font-size: 21px;
    font-weight: 600;
    line-height: 32px;
    margin: 0;
    margin-top: 12px;
  }

  .description {
    font-size: 16px;
    line-height: 24px;
    max-width: 250px;
  }
}

.list {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 164px);
  margin-top: 28px;
  margin-bottom: 28px;
  overflow-y: auto;
}

.item {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 24px 10px 10px;
  border-radius: 8px;
  position: relative;

  &:hover {
    background-color: #e4e9ff;
    cursor: pointer;

    .icon {
      display: block;
    }
  }

  .icon {
    display: none;
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
  }

  .image {
    width: 68px;
    height: 68px;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    margin-right: 64px;
  }

  .labels {
    color: #676879;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    gap: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 340px;
  }

  .point {
    width: 4px;
    height: 4px;
    background-color: #636363;
    border-radius: 50%;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
  }
}
