@import '../../variables.scss';
@import '../../mixins.scss';

.plugins {
  @include centered-main;
  position: relative;
  padding: 144px 10px 208px;
}

.sectionTitle {
  @include main-title(48px, 57.6px);
}

.list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 44px;
}

.item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid #c3c6d4;

  &:last-child {
    border-bottom: none;
  }

  &:not(:first-child):not(:last-child) {
    padding-top: 16px;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    margin: 0;
  }
}

.description,
.personInfo {
  font-size: 16px;
  line-height: 24px;
  color: #676879;
}

.block {
  width: 450px;
}

.personInfo {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 160px;
}

.avatar {
  width: 26px;
  height: 26px;
}

.content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.labels {
  display: flex;
  align-items: center;
  gap: 10px;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  color: #6161ff;
}
