@import '../../variables.scss';
@import '../../mixins.scss';

.title {
  @include main-title(48px, 57.6px, center);
}

.tools {
  position: relative;
  @include centered-main;
  padding: 0 10px;
}

.content {
  margin-bottom: 148px;
}

.search {
  max-width: 548px;
  margin: 28px auto 0;
}

.filtersWrapper {
  margin-top: 54px;
  display: flex;
  align-items: center;
}

.filters {
  display: flex;
  gap: 10px;
}

.label {
  font-size: 18px;
  line-height: 40px;
  letter-spacing: -0.3px;
  margin-right: 28px;
  font-weight: 700;
}

.icons {
  margin-left: auto;
}

.list {
  position: relative;
  min-height: 300px;
}

.shape {
  position: absolute;
  left: -68px;
}
