@import '../../variables.scss';
@import '../../mixins.scss';

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 40px 20px;
  margin-top: 44px;
}

.item {
  width: 304px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.image {
  height: 208px;
  object-fit: cover;

  &:hover {
    cursor: pointer;
  }
}

.title {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 33.6px;
  margin: 0;
  padding: 0;
}

.description {
  font-size: 16px;
  line-height: 24px;
  padding: 0;
  margin: 0;
}

.content {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 12px;
}

.date {
  display: flex;
  align-items: center;
  border-top: 1px solid #d9d9d9;
  padding-top: 8px;
  color: #676879;

  font-size: 12px;
  line-height: 18px;

  margin-top: auto;
}

.labels {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.icons {
  display: flex;
  align-items: center;
  gap: 20px;
}

.inlineWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.link {
  color: #6161ff;
}

.action {
  &:hover {
    cursor: pointer;
  }
}
