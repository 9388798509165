@import "../../../styles/typography";

.container {
  margin-block-start: var(--spacing-medium);

  .icon {
    margin-right: var(--spacing-small);
  }

  .description {
    margin-block-start: var(--spacing-small);
    @include theme-prop(color, secondary-text-color);
  }

  .closeButton {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
