.link {
  font-size: 16px;
  line-height: 24px;

  text-decoration: none;
  color: inherit;

  display: flex;
  align-items: center;
  gap: 6px;

  border-bottom: 1px solid transparent;

  width: max-content;

  &:hover {
    border-bottom-color: inherit;
    cursor: pointer;
  }
}
