@import "../../styles/themes";
@import "../../styles/typography";
@import "../../styles/global-css-settings";

@include create-title-classes;
@include create-text-classes;

.headingComponent {
  border: 1px solid transparent;
  border-radius: $border-radius-small;
  min-width: 0;
  @include heading-padding();
}

.headingComponent.multiLineEllipsis {
  @include line-clamp(var(--heading-clamp-lines));
}

/* This is needed to support ellipsis in the middle of the word */

.headingComponent.singleLineEllipsis {
  @include ellipsis();
}

.headingComponent.suggestEditOnHover:hover {
  cursor: text;
  border: 1px solid;
  @include theme-prop(border-color, ui-border-color);
}

/* Override tags styles */

.elementTypeH1 {
  @include vibe-title("h1", "normal");
  letter-spacing: 0.5px !important;
}

.elementTypeH2.sizeLarge,
.elementTypeH1.sizeSmall,
.elementTypeH1.sizeMedium {
  @include vibe-title("h2", "normal");
  letter-spacing: 0.1px !important;
}

.elementTypeH3.sizeLarge {
  @include vibe-title("h2", "light");
  letter-spacing: 0.1px !important;
}

.elementTypeH2.sizeSmall,
.elementTypeH2.sizeMedium,
.elementTypeH3.sizeSmall,
.elementTypeH3.sizeMedium,
.elementTypeH4 {
  @include vibe-title("h3", "normal");
  letter-spacing: 0.1px !important;
}

.elementTypeH5, .elementTypeP {
  @include vibe-text("medium", "normal");
}

.elementTypeH6 {
  @include vibe-text("small", "normal");
}

.elementTypeSmall {
  @include font-caption();
}

.elementTypeLink {
  text-decoration: none;
  color: var(--link-color);
}
