@import "../../styles/themes";

.wrapper {
  display: flex;
}

.divider {
  @include theme-prop(background-color, ui-border-color);
  margin: 16px;
  height: 1px;
  width: 48px;

  &.compact {
    max-width: 40px;
    min-width: 16px;
    margin-inline-start: var(--spacing-small);
    margin-inline-end: var(--spacing-small);
  }
}
