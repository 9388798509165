@import "../../styles/themes";
@import "../../styles/typography";
@import "../../styles/global-css-settings";

.tipseenWrapper {
  min-width: 100px;
  min-height: 40px;
  --tooltip-padding: 0;
  --tooltip-border-radius: var(--border-radius-medium);

  &WithoutCustomWidth {
    --tooltip-max-width: 320px;
  }

  .tipseen {
    &Header {
      display: flex;
      justify-content: flex-start;
    }

    &Title {
      padding: var(--spacing-medium) var(--spacing-medium) 0 var(--spacing-medium);
    }

    &Content {
      margin: 0;
      @include smoothing-text;
    }

    &CloseButton {
      position: absolute;
      top: var(--spacing-xs);
      right: var(--spacing-xs);
      z-index: 2;

      &.dark {
        color: var(--color-asphalt);
      }
    }
  }
}
