@import '../../variables.scss';
@import '../../mixins.scss';

.wrapper {
  @include centered-column;
  text-align: center;
  margin-top: 60px;
}

.title {
  font-size: 22.7px;
  font-weight: 600;
  line-height: 33.4px;
  margin: 0;
}

.text {
  font-size: 16px;
  line-height: 24px;
}
