.wrapper {
  width: 100%;
  height: 50px;
  position: relative;
}

.input {
  width: 100%;
  height: 100%;

  border-radius: 35px;
  border: 2px solid #dcdfec;
  padding: 8px 24px 8px 60px;

  display: flex;
  align-items: center;

  font-family: Figtree, Poppins, sans-serif;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: #676879;

  &:focus-visible {
    outline: none;
    box-shadow: none;
  }
}

.icon {
  position: absolute;
  
  margin: 0;
  padding: 0;

  left: 24px;
  top: calc(50% - 25px/2);
}
